.title {
  font-size: 24px;
  font-weight: 600;
  position: relative;
  z-index: 2;
}
.titleUnSelected {
  font-size: 24px;
  font-weight: 600;
  color: #999;
}
.cursor {
  cursor: pointer;
}

.line {
  position: absolute;
  top: 26px;
  width: 100%;
  height: 7px;
  background: var(--theme-color);
  opacity: 1;
  border-radius: 100px;
}

.background {
  height: 40px;
  position: relative;
}