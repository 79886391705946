@import '~antd/dist/antd.css';

:root {
  --theme-color: #44D295;
  --background-color: #F1F1F1;
  --width: 100%;
  --max-width: 1024px
}

.flex-row {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}
.flex-sb-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-sb-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainWidth {
  width: var(--width);
  max-width: var(--max-width);
}

.w100p {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.lh37 {
  line-height: 37px;
}

.h40 {
  height: 39px;
}

.w96 {
  width: 96px;
}
.w100 {
  width: 100px;
}

.w366 {
  width: 366px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mr50 {
  margin-right: 50px;
}

.fs16 {
  font-size: 16px !important;
}
.fs20 {
  font-size: 20px !important;
}

.App {
  background: var(--background-color);
}

.theme-color {
  color: #44D295
}

.theme-background-color {
  background-color: #44D295
}

.color-333 {
  color: #333;
}

.color-666 {
  color: #666
}

.container-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.input-bordered {
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  height: 40px;
  width: 368px;
}

/* Bradia Editor */
.image-wrap {
  width: 500px;
}

/* antd */
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: white;
}

.ant-pagination-item-active a {
  color: white;
}

.ant-pagination-item-active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.ant-modal-body {
  padding: 0
}

/* 官方主页 */
.banner-wrap {
  width: var(--width);
  max-width: var(--max-width);
  height: 350px;
  background-color: silver;
  border-radius: 15px;
}

.challenge-wrap {
  margin-top: 40px;
  height: 282px;
  width: var(--width);
  max-width: var(--max-width);
}

.title-wrap {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
}

.more {
  margin-left: 30px;
  font-size: 14px;
  color: #44D295;
}

.content-wrap {
  width: var(--width);
  max-width: var(--max-width);
  height: 226px;
  display: flex;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}

.picture {
  width: 226px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.content {
  margin: 30px;
  width: 738px;
}

.up-wrap {
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: 24px;
}

.text-align-center {
  text-align: center;
}

.detial {
  font-size: 16px;
}

.middle-wrap {
  display: flex;
}

.number {
  font-size: 14px;
  color: #999;
}

.popularity {
  margin-left: 10px;
  font-size: 14px;
  color: #999;
}

.down-wrap {
  margin-top: 30px;
}

/* 主页 Home */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.home-swiper {
  width: var(--width);
  max-width: var(--max-width);
  height: 50vw;
  max-height: 350px;
  border-radius: 15px;
  margin: 30px 0;
}

.home-list-wrap {
  display: flex;
  width: var(--width);
  max-width: var(--max-width);
}

.home-list {
  width: 512px;
  margin: 40px 0;
}

.home-options {
  display: flex;
  justify-content: space-between;
  width: var(--width);
  max-width: var(--max-width);
}
/* @media ( max-width: 1024px ) {
  .home-options {
    display: flex;
    justify-content: center;
    width: var(--width);
    max-width: var(--max-width);
    flex-wrap: wrap;
  }
} */

.home-post {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 330px;
  height: 120px;
  padding: 20px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}
/* .option-image {
  width: var(--width)/3;
  height: var(--width)/9;
} */
.home-activity {
  margin-top: 10px;
  width: 330px;
  height: 120px;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
  cursor: pointer;
}

.home-activity:hover {
  opacity: 0.8;
}

.feeds-comments {
  width: 100%;
  background-color: #f8f8f8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.feeds-wrap {
  width: 500px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
}

.feeds-wrap-up {
  padding: 30px 30px 10px 30px;
}

.feeds-picture {
  height: 440px;
  width: 440px;
}

.feeds-content-wrap {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.feeds-content {
  margin: 20px 0;
  width: 100%;
}

.feeds-publisher-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feeds-publisher-info {
  display: flex;
  align-items: center;
}

.feeds-publisher-name {
  font-size: 14px;
}

.feeds-publisher-date {
  font-size: 12px;
  color: #999;
}

.feeds-tag {
  color: #999;
  font-size: 14px;
  margin: 10px 0;
}

.feeds-comments-wrap {
  width: 100%;
}

.feeds-comment-content-wrap {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0 30px;
}

/* 创意挑战详情 ChallengeDetail */
.challenge-detail-list {
  margin-top: 40px;
  width: var(--width);
  max-width: var(--max-width);
}

.card-wrap {
  width: 321px;
  height: 464px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
}

.card-picture {
  height: 321px;
  width: 321px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.challengedetail-content-wrap {
  height: 143px;
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  font-size: 14px;
  position: relative;
}

.challengedetail-content {
  width: 281px;
  margin: 20px 0 0 20px;
}

.publisher-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 20px;
  position: absolute;
  bottom: 20px;
  width: 281px;
}

.publisher-info {
  display: flex;
  align-items: center;
}

.publisher-name {
  margin-left: 10px;
  font-size: 14px;
}

/* 创作详情 */
.detail-modal {
  display: flex;
}

.detail-picture {
  width: 400px;
  height: 400px;
}

.detail-wrap {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.creation-image-wrap {
  width: 600px;
  height: 600px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-wrap {
  min-height: 160px;
  max-height: 190px;
  width: 440px;
  background-color: white;
  padding: 15px;
}

.post-publisher-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-publisher-time {
  color: #999999;
  font-size: 12px;
  margin-left: 10px
}

.comment-wrap {
  max-height: 340px;
  flex: 1;
  width: 440px;
  background-color: #f8f8f8;
  overflow: auto;
}

.post-publisher-info {
  display: flex;
  flex-direction: column;
}

.comment-post-wrap {
  position: absolute;
  bottom: 0;
  /* right: 0; */
  height: 70px;
  width: 440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 27px 30px;
}

.comment-input {
  padding: 0 5px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  width: 332px;
  height: 42px;
}

/* 日记详情 */
.diary-wrap {
  padding: 20px;
  overflow: auto;
  width: 500px;
  height: 600px;
  background-color: #f1f1f1;
}

.diary-title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
}

.diary-post-wrap {
  width: 440px;
  background-color: white;
  padding: 15px;
}

.diary-comment-wrap {
  height: 404px;
  width: 440px;
  background-color: #f8f8f8;
  overflow: auto;
}

/* 个人主页 */
.profile-wrap {
  width: var(--width);
  max-width: var(--max-width);
  height: 166px;
  padding: 30px 30px 28px 30px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}

.profile-left {
  display: flex;
}

.profile-avatar {
  width: 108px;
  height: 108px;
  border-radius: 54px;
}

.profile-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}

.profile-name {
  font-size: 24px;
  font-weight: 500;
}

.profile-age-wrap {
  display: flex;
  justify-content: space-between;
}

.profile-age {
  font-size: 14px;
  color: #999;
  margin-right: 5px;
}

.profile-desc {
  font-size: 14px;
}

.profile-post {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-menu-container-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 30px 0;
}

.profile-menu {
  width: var(--width);
  max-width: var(--max-width);
}

/* 编辑信息 */
.profile-edit-wrap {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding: 30px;
  width: 1024px;
  background-color: white;
  border-radius: 15px;
}

.profile-edit-right {
  margin-left: 80px;
}

/* 我的日记 */
.profile-diary-item-wrap {
  width: var(--width);
  max-width: var(--max-width);
  padding: 20px;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}

.profile-diary-title {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.profile-diary-content {
  font-size: 14px;
  color: #333;
}

/* 我的关注 */
.follower-wrap {
  width: var(--width);
  max-width: var(--max-width);
  padding: 30px 30px 28px 30px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}

.follower-avatar {
  width: 88px;
  height: 88px;
  border-radius: 44px;
}

.diary-content img {
  width: 460px;
}

/* 艺术馆 */
.museum-picture {
  width: 244px;
  height: 244px;
  border-radius: 15px;
  opacity: 0.9;
  color: black;
  background: rgba(0, 0, 0, 0.8);
}

.featured-image {
  display: table-cell;
  position: relative;
  /* transition: opacity .25s ease-in-out, background .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out, background .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out, background .25s ease-in-out; */
  border-radius: 15px;
  vertical-align: middle;
  color: #fff;
  /* text-decoration: none; */
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.8);
}

.museum-image-wrap {
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
  bottom: 0;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}

.museum-image-wrap h1.entry-title {
  display: table;
  font-size: 20px;
  height: 100%;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
}

/* Support */
.support-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.support-picture {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.support-name {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.support-role {
  font-size: 14px;
  color: #666666;
  font-weight: 500;
}
.support-brief {
  font-size: 12px;
  color: #333;
}

/* mobile样式 */
/* BEGIN 创意挑战 */
.container-wrap-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.content-wrap-mobile {
  width: var(--width);
  max-width: var(--max-width);
  /* height: 226px; */
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  padding: 10px;
}

.picture-mobile {
  width: var(--width);
  height: 100vw;
}

.content-mobile {
  /* margin: 30px; */
  width: 60%;
}
.challenge-up-wrap-mobile {
  display: flex;
  flex-direction: column;
}
.challenge-down-wrap-mobile {
  /* margin-top: 10px; */
  width: 100%;
}
.challenge-up-wrap-mobile .right-wrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button-width {
  width: 130px;
}
.card-wrap-mobile {
  border-radius: 15px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
}

.card-picture-mobile {
  width: var(--width);
  height: 100vw;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.challengedetail-content-mobile {
  width: 90%;
  margin: 20px 0 0 20px;
}
.publisher-wrap-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 20px;
  position: absolute;
  bottom: 20px;
  width: 90%;
}
/* END 创意挑战 */

.museum-detail-right-wrap {
  margin-left: 10px;
  display: flex;
}

/* Home主页 */
.home-post-mobile {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: var(--width)/3;
  height: var(--width)/9;
  padding: 20px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}
.home-options-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--width);
  max-width: var(--max-width);
}
.home-list-mobile {
  width: var(--width);
}
.home-activity-mobile {
  margin-top: 10px;
  width: var(--width);
  max-width: var(--max-width);
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
  cursor: pointer;
}
.feeds-wrap-mobile {
  width: var(--width);
  border-radius: 15px;
  background-color: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
}
.feeds-wrap-up-mobile {
  padding: 10px 10px 10px 10px;
}

.feeds-picture-mobile {
  height: 90vw;
  width: 90vw;
}
.home-tab-mobile {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.padding-10 {
  padding: 10px 10px;
}
.padding-row-10 {
  padding: 0px 10px;
}
.padding-column-10 {
  padding: 10px 0px;
}
/* BEGIN 宋佳芸做的修改 */
.topicDetail-container-wrap-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topicDetail-content-wrap-mobile{
  /* width: var(--width); */
  /* max-width: var(--max-width); */
  display: flex;
  height: 100%;
  flex-direction: column;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  padding-top: 10px;
}

.topicDetail-name-mobile{
  display: flex;
  font-size: 24px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.topicDetail-picture-mobile{
  display: flex;
  width: 120px;
  height: 120px;
  margin-right:15px;
}

.topicDetail-content-mobile{
  display: flex;
  flex-direction:column;
}

.topicDetail-up-wrap-mobile{
  display:flex;
  flex-direction:row;
  margin-left: 15px;
  margin-right:15px;
}

.topicDetail-up-right-wrap-mobile{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.topicDetail-middle-wrap-mobile{
  display: flex;
  margin-top: 5px;
  margin-left: 15px;
}

.topicDetail-down-wrap-mobile{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 90%;
}
/* END 宋佳芸做的修改 */
/* BEGIN 官方主页 */
.challenge-wrap-mobile {
  margin-top: 40px;
  width: var(--width);
  max-width: var(--max-width);
}
/* END 官方主页 */
/* BEGIN 艺术馆 */
.museum-picture-mobile {
  width: 100%;
  height: 100vw;
  border-radius: 15px;
  opacity: 0.9;
  color: black;
  background: rgba(0, 0, 0, 0.8);
}
/* END 艺术馆 */
/* BEGIN 个人主页 */
.profile-avatar-mobile {
  width: 80px;
  height: 80px;
  border-radius: 54px;
}
.profile-wrap-mobile {
  width: var(--width);
  max-width: var(--max-width);
  height: 166px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}
/* END 个人主页 */
/* BEGIN我的关注 */
.follower-wrap-mobile {
  width: var(--width);
  max-width: var(--max-width);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  border-radius: 15px;
}
/* END 我的关注 */
/* BEGIN 编辑信息 */
.profile-edit-wrap-mobile {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: var(--width);
  max-width: var(--max-width);
  background-color: white;
  border-radius: 15px;
}
.input-bordered-mobile {
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  height: 40px;
  width: 80%;
}

/* END 编辑信息 */
/* BEGIN 创意和日记详情 */
.creation-image-wrap-mobile {
  width: 100%;
  padding: 40px 0 20px 0;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-modal-mobile {
  display: flex;
  flex-direction: column;
}
.diary-wrap-mobile {
  padding: 20px;
  overflow: auto;
  /* height: 600px; */
  background-color: #f1f1f1;
}

.diary-post-wrap-mobile {
  /* width: 440px; */
  background-color: white;
  padding: 15px;
}

.diary-comment-wrap-mobile {
  height: 404px;
  /* width: 440px; */
  background-color: #f8f8f8;
  overflow: auto;
}
.comment-post-wrap-mobile {
  position: absolute;
  bottom: 0;
  /* right: 0; */
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 20px 10px;
}
.comment-input-mobile {
  padding: 0 5px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  height: 42px;
}
.detail-picture-mobile {
  width: 100%;
  height: 100vw;
}
.post-wrap-mobile {
  /* min-height: 160px;
  max-height: 190px; */
  width: 100%;
  background-color: white;
  padding: 15px;
}
/* END 创意和日记详情 */
/* BEGIN topic */
.topic-up-wrap-mobile {
  writing-mode:horizontal-lr;
  display: flex;
  flex-direction:row;
  background-color:rgba(255, 255, 255, 0.694);
  border-radius:12px;
  padding:10px;
  text-align: center;
}
.topic-down-wrap-mobile {
  width: 100%;
  padding:10px;
  background-color:rgba(255, 255, 255, 0.694);
  border-radius:12px;
}
.topic-up-wrap-mobile .right-wrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pic-mobile {
  border-radius:12px;
  align-items: center;
  width: 50%;
  height:50vw;
}
.picture-container{
  width: 380px;
  display: flex;
  justify-content: center;
}
.topic-up-wrap-mobile-center .right-wrap-center {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.discussion-container{
  font-size:14px;
  display: flex;
  justify-content:center;
  font-weight: bold;
}
.name-mobile {
  font-size: 24px;
  display: flex;
  justify-content:center;
}
.content-wrap-mobile-vertical {
  /* width: var(--width);
  max-width: var(--max-width); */
  /* height: 226px; */
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 7px 7px 34px rgba(182, 182, 199, 0.23);
  opacity: 1;
  padding: 10px;
}
/* BEGIN 解决加了Spin标签后，百分比宽度无法继承传递下去的问题 */
.topicDetail-container-wrap-mobile .ant-spin-nested-loading {
  width: var(--width);
  max-width: var(--max-width);
}
.container-wrap .ant-spin-nested-loading {
  width: var(--width);
  max-width: var(--max-width);
}
.profile-menu-container-wrap .is-mobile {
  width: var(--width);
  max-width: var(--max-width);
}
/* END 解决加了Spin标签后，百分比宽度无法继承传递下去的问题 */